/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import 'popper.js';
import 'bootstrap';
import tippy from 'tippy.js';

import Rails from "@rails/ujs";
import '@hotwired/turbo-rails';

import 'controllers/index';
import 'src/tooltips';

import '../stylesheets/application.sass';

window.tippy = tippy;

Rails.start();
