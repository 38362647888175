import { delegate } from 'tippy.js'

document.addEventListener('turbo:load', () => {
  delegate( '.admin-body', {
    target: '[data-tippy-content]',
    theme: 'light',
    popperOptions: {
      strategy: 'fixed'
    }
  });
})
